import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Label } from "@mui/icons-material";

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    useEffect(() => {
        console.log("Oscar", i18n.language);
        if (i18n.language != "pt-BR" || i18n.language != "es-ES" || i18n.language != "en-US") {
            i18n.changeLanguage("en-US");
        }
    }, []);

    return (
        <FormControl fullWidth variant="outlined" margin="normal" style={{ width: 70 }}>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                onChange={handleChange}
                label="Idioma"
            >
                <MenuItem value="en-US">🇺🇸</MenuItem>
                <MenuItem value="pt-BR">🇧🇷</MenuItem>
                <MenuItem value="es-ES">🇪🇸</MenuItem>
                <MenuItem value="fr-FR">🇫🇷</MenuItem>
                <MenuItem value="it-IT">🇮🇹</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
