import { Image } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { InlineWidget } from "react-calendly";
import ReactPlayer from "react-player";

export default function HeroInfinity() {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    }

    return (
        <Stack
            style={{
                backgroundColor: "black",
                position: 'relative',
                overflow: 'hidden',
                width: "100vw",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                justifyContent: "center",
                alignItems: "center"
            }}
            sx={{
                // backgroundImage: {
                //     xs: 'url(/morena2.png)',
                //     md: 'url(/morena2.png)',
                // },
                height: {
                    xs: "75vh",
                    md: "100vh",
                }
            }}
        >
            <Stack style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                display: "flex",
                top: 0,
            }}>
                <Stack sx={{
                    minHeight: {
                        xs: 80,
                        md: 100,
                        lg: 200,
                    }
                }} />
                <video
                    controls={false}
                    disablePictureInPicture
                    autoPlay
                    playsInline
                    muted
                    loop style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}>
                    <source src="5822800-hd_1920_1080_25fps.mp4" type="video/mp4" />
                </video>
            </Stack>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{}}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {"Schedule a meeting with us"}
                </DialogTitle>
                <DialogContent>
                    <InlineWidget url="https://calendly.com/infinitoclinic" />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Fechar
                    </Button>
                </DialogActions> */}
            </Dialog>
        </Stack>
    );
}