import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';

import Sitemark from './SitemarkIcon';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

function AppAppBar({ mode, toggleColorMode }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 0 }}
    >
      <Stack maxWidth="xxl">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            backdropFilter: 'blur(24px)',
            height: {
              xs: 80,
              sm: 100,
            },
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'transparent',
          })}
        >
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{}}
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              {"Schedule a meeting with us"}
            </DialogTitle>
            <DialogContent>
              <InlineWidget url="https://calendly.com/infinitoclinic" />
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Fechar
              </Button>
            </DialogActions> */}
          </Dialog>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0, position: "relative" }}>
            <Sitemark />
            <Box sx={{ display: { xs: 'none', md: 'flex', width: "100%", justifyContent: "flex-end" } }}>
              <Button
                variant="text"
                color="info"
                size="large"
                style={{
                  fontSize: '1.25rem',
                }}
                onClick={() => setOpenDialog(true)}
              >
                {t('menu_title_one')}
              </Button>
              <Button
                variant="text"
                color="info"
                size="large"
                style={{
                  fontSize: '1.25rem',
                }}
                onClick={() => scrollToSection('features')}
              >
                {t('menu_title_two')}
              </Button>
              <Button
                variant="text"
                color="info"
                size="large"
                style={{
                  fontSize: '1.25rem',
                }}
                onClick={() => scrollToSection('services')}
              >
                {t('menu_title_three')}
              </Button>
              <Button
                variant="text"
                color="info"
                size="large"
                style={{
                  fontSize: '1.25rem',
                }}
                onClick={() => scrollToSection('contactus')}
              >
                {t('menu_title_four')}
              </Button>
              <LanguageSelector />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            {/* <ToggleColorMode
              data-screenshot="toggle-mode"
              mode={mode}
              toggleColorMode={toggleColorMode}
            /> */}
            {/* <Button color="primary" variant="text" size="small">
              Sign in
            </Button>
            <Button color="primary" variant="contained" size="small">
              Sign up
            </Button> */}
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon htmlColor='#E59E34' color='#E59E34' style={{
                height: 40,
                width: 40,
              }} />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'transparent', width: 350 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon htmlColor='#E59E34' color='#E59E34' />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => setOpenDialog(true)}>
                  {t('menu_title_one')}
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('features')}>
                  {t('menu_title_two')}
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('services')}>
                  {t('menu_title_three')}
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('contactus')}>
                  {t('menu_title_four')}
                </MenuItem>
                <MenuItem>
                  {t('menu_title_six')}
                </MenuItem>
                <MenuItem>
                  <LanguageSelector />
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Stack>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
