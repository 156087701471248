import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { Button, Grow, Stack } from '@mui/material';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import { useNavigate } from 'react-router-dom';

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

const items = [
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Hair transplant',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageLight: 'Trnasplant2.jpeg',
    imageDark: 'Trnasplant2.jpeg',
  },
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Aesthetic medicine',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageLight: 'people-2568524_1280.jpg',
    imageDark: 'treat.jpeg',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Hair treatment',
    description:
      'Navigating life’s intricate fabric, choices unfold paths to the extraordinary, demanding creativity, curiosity, and courage for a truly fulfilling journey.',
    imageDark: 'tratamento_capilar.jpg',
  },
];

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = React.useState(false);
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log(entry.isIntersecting);
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.05, // Define quando a animação dispara (30% do elemento visível)
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Limpa o observador quando o componente desmonta
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <Container
      id="services"
      ref={sectionRef}
      sx={{

        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Grow in={isVisible} timeout={1000}>
        <Grid container spacing={6} marginTop={0}>
          <Grid item xs={12} md={12}>
            <div>
              <Typography
                id="services"
                style={{ marginBottom: 15, color: "#E59E34" }}
                component="h2" variant="h4" sx={{
                  color: 'text.primary',
                  fontSize: { xs: '1.8rem', md: '2.3rem' },
                  id: 'service',
                }}>
                {t('service_title')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '', mb: { xs: 2, sm: 4 },
                  fontSize: { xs: '1.5rem', md: '2rem' },
                }}
              >
                {t('service_subtitle')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grow>
      <Grow in={isVisible} timeout={1500}>
        <Grid container gap={0} style={{
        }}>
          <Grid xs={12} sm={12} md={4} lg={4} padding={1}
            style={{
              cursor: 'pointer',
            }}
          >
            <Stack
              // onClick={() => {
              //   const paramsJson = JSON.stringify(items[0])
              //   const utf8Bytes = new TextEncoder().encode(paramsJson)
              //   const base64String = btoa(String.fromCharCode(...utf8Bytes))
              //   navigate("/service/" + base64String)
              // }}
              direction="column" useFlexGap flex={1} style={{ position: "relative" }}>
              <img src={items[0].imageDark} alt="image" style={{ borderRadius: 10, objectFit: "cover", objectPosition: "10% 20%", height: 550 }} />
              <Button
                variant="text" color="info" size="large" style={{ fontSize: '1.25rem', position: "absolute", bottom: 0, right: 10 }}>
                {t('title_service_three')}
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} padding={1}
            style={{
              cursor: 'pointer',
            }}
          >
            <Stack
              // onClick={() => {
              //   const paramsJson = JSON.stringify(items[1])
              //   const utf8Bytes = new TextEncoder().encode(paramsJson)
              //   const base64String = btoa(String.fromCharCode(...utf8Bytes))
              //   navigate("/service/" + base64String)
              // }}
              direction="column" useFlexGap flex={1} style={{ position: "relative" }}>
              <img src={items[1].imageDark} alt="image" style={{ borderRadius: 10, objectFit: "cover" }} />
              <Button variant="text" color="info" size="large" style={{ fontSize: '1.25rem', position: "absolute", bottom: 0, right: 10 }}>
                {t('title_service_two')}
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} padding={1}
            style={{
              cursor: 'pointer',
            }}
          >
            <Stack
              // onClick={() => {
              //   const paramsJson = JSON.stringify(items[2])
              //   const utf8Bytes = new TextEncoder().encode(paramsJson)
              //   const base64String = btoa(String.fromCharCode(...utf8Bytes))
              //   navigate("/service/" + base64String)
              // }}
              direction="column" useFlexGap flex={1} style={{ position: "relative" }}>
              <img src={items[2].imageDark} alt="image" style={{ borderRadius: 10, objectFit: "cover" }} />
              <Button variant="text" color="info" size="large" style={{ fontSize: '1.25rem', position: "absolute", bottom: 0, right: 10 }}>
                {t('title_service_one')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grow>
    </Container>
  );
}
