// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en/translation.json';
import esTranslation from './locales/es/translation.json';
import ptTranslation from './locales/pt/translation.json';
import ftTranslation from './locales/fr/translation.json';
import itTranslation from './locales/it/translation.json';

// Traduções em diferentes línguas
const resources = {
    en: { translation: enTranslation },
    es: { translation: esTranslation },
    pt: { translation: ptTranslation },
    fr: { translation: ftTranslation },
    it: { translation: itTranslation },
};
// Inicializar o i18next
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
