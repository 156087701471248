import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Stack } from '@mui/material';

export default function SitemarkIcon() {
  return (
    <Stack style={{ 
      position: "absolute"
     }}>
      <img src="LogoInfinito.png" alt="Sitemark" width={200} height={200} style={{objectFit: "contain", border: 0, borderWidth: 0}}/>
    </Stack>
  );
}
