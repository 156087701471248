// src/ServicePage.js

import React from 'react';
import { Container, Typography, Box, Button, Grid, Paper } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

// Função para decodificar de Base64 para Objeto (UTF-8 seguro)
function fromBase64(base64String) {
  // 1. Decodifique a string Base64 para uma string em Latin1
  const latin1String = atob(base64String);

  // 2. Converta a string Latin1 para uma sequência de bytes UTF-8
  const utf8Bytes = Uint8Array.from(latin1String, char => char.charCodeAt(0));

  // 3. Decodifique os bytes UTF-8 para uma string
  const jsonString = new TextDecoder().decode(utf8Bytes);

  // 4. Parseie a string JSON de volta para um objeto
  return JSON.parse(jsonString);
}

const ServicePage = ({ item }) => {
  const { service } = useParams(); // Obtendo o parâmetro "name" da URL
  const serviceData = fromBase64(service)

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        {/* Título do serviço */}
        <Typography variant="h4" component="h1" gutterBottom>
          {serviceData?.title}
        </Typography>

        {/* Descrição do serviço */}
        <Typography variant="body1" paragraph>
          {serviceData?.description}
        </Typography>

        {/* Seção de serviços */}
        {/* <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Typography variant="h6">Consultoria Empresarial</Typography>
              <Typography variant="body2" paragraph>
                Oferecemos consultoria para empresas em busca de inovação e crescimento estratégico. Nossa abordagem é
                focada em resultados práticos e eficiência.
              </Typography>
              <Button variant="contained" color="primary">
                Saiba Mais
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Typography variant="h6">Desenvolvimento Web</Typography>
              <Typography variant="body2" paragraph>
                Criamos websites modernos e funcionais com tecnologias de ponta, garantindo que sua presença online seja
                impactante e acessível.
              </Typography>
              <Button variant="contained" color="primary">
                Saiba Mais
              </Button>
            </Paper>
          </Grid>
        </Grid> */}
      </Paper>
    </Container>
  );
};

export default ServicePage;
