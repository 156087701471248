import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

const StoryContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2.5rem',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const StoryText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  lineHeight: 1.7,
  fontSize: '1.2rem',
}));

const Image = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
}));

const OurStory = () => {
  return (
    <StoryContainer maxWidth="lg">
      {/* Título */}
      <SectionTitle variant="h2">Our Story</SectionTitle>

      {/* Texto de introdução */}
      <StoryText variant="body1">
        Our journey began with a passion for creating handmade, eco-friendly products
        that not only bring joy to our customers but also have a positive impact on the
        environment. From humble beginnings to growing into a brand that stands for
        sustainability and innovation, we have always stayed true to our core values.
      </StoryText>

      {/* Seção de Imagens e Descrição */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Image src="https://via.placeholder.com/500" alt="Our Beginning" />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoryText variant="body1">
            What started as a small project has now grown into something we are proud of.
            We believe in the power of creativity and the importance of craftsmanship in
            everything we do. Each of our products tells a story—our story, and we are
            excited to share it with the world.
          </StoryText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Image src="https://via.placeholder.com/500" alt="Our Growth" />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoryText variant="body1">
            Today, our team is more passionate than ever about delivering quality,
            handmade products that bring a little bit of happiness to our customers' lives.
            We are committed to pushing the boundaries of what we can create while
            ensuring that we remain environmentally conscious.
          </StoryText>
        </Grid>
      </Grid>

      {/* Texto Final */}
      <Box textAlign="center" mt={6}>
        <StoryText variant="body1">
          Thank you for being part of our journey. We are excited to see what the future holds.
        </StoryText>
      </Box>
    </StoryContainer>
  );
};

export default OurStory;
