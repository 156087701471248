import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage';
import ComingSoon from './comingSoon';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./i18net";
import OurStory from './components/OurStory';
import ServicePage from './components/ServicePage';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/mariola" element={<LandingPage />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/service/:service" element={<ServicePage />} />
        </Routes>
      </Router>
  );
}

export default App;
